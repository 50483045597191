import React, { Component } from 'react';
import {Logo, Form, Toast} from 'polar-ui';
// import {NavLink} from 'react-router-dom';
import RJSF from "react-jsonschema-form";

import UAPI from '../../../api/users';

import './index.css';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  async login(formData){

    const email =  formData.email;
    let password = formData.password;
    await UAPI.login({email, password})
    .then(res => {

      this.props.history.push('/explore');
      return res.data;
    }).catch(e => {
      Toast.setWarning("Invalid email or password");
    });
  }
  // validate(formData, errors) {
  //   console.log("validating");
  //   if (true) {
  //     errors.password.addError("555!");
  //   }
  //   return errors;
  // }
  // ErrorListTemplate(props) {
  //   const {errors} = props;
  //   console.log("sssssssssssssssssssssssssssssssssssssssss");
  //   console.log(errors);
  //   return (
  //     <ul>
  //       {errors.map(error => (
  //           <li key={error.stack}>
  //             {(error.stack)}
  //           </li>
  //         ))}
  //
  //     </ul>
  //   );
  // }
  render(props) {
    const schema = {
      "title": "Login",
      "type": "object",
      "required": [
        "email",
        "password"
      ],
      "properties": {
        "email": {
          "type": "string",
          "format": "email",
          "title": "Email"

        },
        "password": {
          "type": "string",
          "title": "Password",
          "minLength": 3,
        },
      }
    };
    let formData = {

    };
    const uiSchema = {
      "password": {
        "ui:widget": "password",
      },
    };
    const log = (type) => console.log.bind(console, type);
    const onSubmit = ({formData}, e) => {
      // console.log("Data submitted: ",  formData);
      return this.login(formData);
    }
    // const validate = (formData, errors) => {
    //   console.log(errors);
    //   errors.addError("sdf")
    //   return this.login(formData)
    //   .then(res => {
    //     return true;
    //   })
    //   .catch(e => {
    //     console.log('caught error in login',e);
    //     throw e;
    //   });
    //
    // }
    // onChange={log("changed")}
    // onError={log("errors")} />
    //
    //
    //
    //         // <RJSF
            //   schema={schema}
            //   uiSchema={uiSchema}
            //   onSubmit={onSubmit}
            //   // validate={validate}
            //   // ErrorList={this.ErrorListTemplate}
            // />
    const {error} = this.state;
    return (
      <div className="container login">
      <Form
        Driver={RJSF}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        // errors={error?(<li>{error.stack}</li>):''}
      />



      </div>

    );
  }
}

export default Login;
