import React, { Component } from 'react';
import {ListItem, Grid, GridItem} from 'polar-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResourceDescription from '../../resources/ResourceDescription';

//remove later
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFilm
  } from '@fortawesome/free-solid-svg-icons';

library.add(faFilm);


class ResourceItem extends Component {
  shouldComponentUpdate(nextProps) {
    const {selected: propSelected, data} = this.props;
    if(nextProps.selected !== propSelected){
      return true;
    }
    if (data.id !== nextProps.data.id){
      return true;
    }
    return false;
  }

  render(props) {
    const {data} = this.props;
    const {
      id,
      publicId,
      title,
      selected,
      sources,
      creators,
    } = data;
    const {handleItemSelect, handleItemEnter} = this.props;

    let thumbnail;
    if(sources && sources.length > 0)
      thumbnail = sources[0].thumbnailUrl;
    return (
      <GridItem
        id={publicId}
        data={data}
        // selected={selected} // not sure this is used
        // identifier={sources && sources.length > 0 && sources[0].identifier}
        creator={creators}
        thumbnail={thumbnail}
        icon={
          <FontAwesomeIcon
          icon="film" />
        }

        handleItemSelect={handleItemSelect?(id)=>this.props.handleItemSelect(id):null}
        handleItemEnter={handleItemEnter?(id)=>this.props.handleItemEnter(id):null}
      >
        <ResourceDescription data={data} granularity="1" api="/explore/"/>
      </GridItem>
    );
  }
}

export default ResourceItem;
