import React, { Component } from 'react';
import Request from 'react-http-request';
import {List, ListItem, Loading, Pane, ItemDescription, SocialIcons, Grid} from 'polar-ui';
import ResourceList from '../../resources/ResourceList';
import ResourceDescription from '../../resources/ResourceDescription';
import ResourcesAPI from '../../../api/resources';
//change
import SearchTools from '../../../api/search';

import axios from 'axios';
import {NavLink} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import './index.css';

class Archive extends Component {

  constructor(props) {
    // console.log(1);
    super(props);
    this.state = {
      data: {

      },
      selectedItem:"",
      resources:[],
      header:"all",
      hasMoreItems:true,
      page: 1,
      skipPremiere: true,
      loading: false,

    }
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.fetchSchema = this.fetchSchema.bind(this);
    this.pane = React.createRef();
    this.container = React.createRef();

    // console.log(11);
  }
  fetchSchema() {
    if(!this.state.schema) {
      ResourcesAPI.getSchema({})
          .then(res => {
            this.setState({schema: res.data})
          })
          .catch(e => {console.log(e)});
    }
  }
  gotResources(data, page) {

    // console.log(2);

    let resources = [];
    if(data && data.hits) {
      resources = data.hits;
    }
    const existingResources = this.state.resources || [];
    existingResources.push.apply(existingResources, resources);
    this.setState({resources: existingResources, loading: false, hasMoreItems: resources.length === 20});
    if(page){
      this.setState({page: page+1})
    }
    // console.log(22);
  }

  async fetchResources(){
    this.fetchSchema();
    if(this.state.loading){
      // console.log(30);
      return;
    }
    this.setState({header:"all", loading: true});
    const terms = this.getSearchTerms(this.props);
    // experimental

    let header = this.getHeaderFromUrl(this.props);
    if(!header){
      header = terms;
    }
    if(terms){
      // console.log(31);

      // return this.handleSearch(terms);
      // if header is passed in the url along with the keywords:
      return this.handleSearch(terms, header);
    }
    const page = this.state.page;


    const {match: {params: {id}}} = this.props;

    this.setState({header:"", loading: true});
    //added page
    let model = id ? {publicId: id} : {};
    await ResourcesAPI.search({}, model, page)
        .then(res => {
          return this.gotResources(res.data, page);
        })
        .catch(e => {console.log(e)});
  }
  async componentWillReceiveProps(nextProps){
    // console.log(4);
    // this.fetchResources(nextProps);
    //
    // console.log("this.props", this.props);
    // console.log("nextProps", nextProps);
    if(this.props.location.key === nextProps.location.key){
      return;
    }
    if(this.state.loading){
      // console.log(40);
      return;
    }
    const terms = this.getSearchTerms(nextProps);
    // experimental
    const header = this.getHeaderFromUrl(this.props);

    if(terms){
      // console.log(41);
      // return this.handleSearch(terms);
      // if header is passed in the url along with the keywords:
      return this.handleSearch(terms, header);
    }

    const {match: {params: {id}}} = nextProps;
    if(id){
      this.setState({header:"", loading: true, resources:[], page:1});
      // Todo: What's this!??
      // added page
      ResourcesAPI.findResources({}, {search: [id], page:1})
      .then(res => {
        // console.log(42);
        this.gotResources(res.data, 1);
        // this.setState({resources:res.data, hasMoreItems:false, loading: false});

      })
      .catch(e => {console.log(e)});
    }
    else {
      // if(this.state.skipPremiere){
      //   console.log(430);
      //   this.setState({skipPremiere:false});
      //   return;
      // }
      this.setState({loading: true, resources:[], page:1});
      await ResourcesAPI.getResources({}, {page:1})
      .then(res => {
        // console.log(43);
        this.gotResources(res.data, 1);
        this.setState({header:"all"});
      })
      .catch(e => {console.log(e)});
    }
  }
  // Using old api
  // handleItemSelect(id) {
  //   if(id){
  //     ResourcesAPI.getResource({}, {publicId: id})
  //     .then(res => {
  //       if(res.data && res.data.resource)
  //         this.setState({data: res.data.resource, selectedItem:id});
  //       // console.log("pane ref", this.pane);
  //       // this.pane.current.scrollTo(0,0);
  //     })
  //     .catch(e => {console.log(e)});
  //   }
  // }
  handleItemSelectForce(id) {
    let model = id ? {publicId: id} : {};
    ResourcesAPI.search({}, model)
        .then(res => {
          if(res.data.length == 1)
            this.setState({data: res.data[0], selectedItem:id});
        })
        .catch(e => {console.log(e)});
  }
  handleItemSelect(item) {
    if (item && item.publicId)
      this.setState({data: item, selectedItem: item.publicId});
  }

  handleItemEnter(id) {}

  getSearchTerms(p) {
    const search = p.location?p.location.search:null; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    return params.get('keywords'); // bar
  }
  getHeaderFromUrl(p) {
    const search = p.location?p.location.search:null; // could be '?foo=bar'
    const params = new URLSearchParams(search);

    return params.get('header'); // bar
  }
  handleSearch(terms, header) {
    if(terms) {
      this.setState({header: header, loading: true});
      terms = SearchTools.parse(terms);
      const page = this.state.page;

      let model = {$terms: terms};
      ResourcesAPI.search({}, model, page)
          .then(res => {
            if (page === 1)
              window.scrollTo(0, 0);
            return this.gotResources(res.data, page);
          })
          .catch(e => {
            console.log(e)
          });
    }
  }

  render() {
    // const loader = <div className="loader">...</div>;
    const empty = [{}]
    const loader = (
      <ResourceList key="resources"
        data={empty}
      />
    );
    // const loader = <div className="loader">...</div>;
    //
    //
    const {header, loading, schema, resources, data, hasMoreItems} = this.state;
    let resourcesWord = resources.length === 1 ? "item" : "items";

    let pane_content = null;
    if(data && Object.keys(data).length !== 0) {
      pane_content = (
        <ItemDescription >
          <ResourceDescription data={data} granularity="2" api="/explore/"/>
        </ItemDescription>
      );
    }
    // <Pane ref={this.pane} level="b1" className={this.state.data?'active':''}>
    //   {pane_content}
    // </Pane>
    return (
      <React.Fragment>
      <Pane ref={this.pane} className={this.state.data?'active':''}>
        {pane_content}
      </Pane>
      <div className="ghost"></div>
      <div className="container scootch" ref={this.container}>
        <header style={{marginBottom: "22px"}}>
          <p>showing: <strong>{this.state.header}</strong> - {this.state.loading?'loading...':`${this.state.resources.length} ${resourcesWord}`}
            <NavLink to="/explore/"> <strong className="clear-filter"> reset view </strong></NavLink>
          </p>
        </header>

        <section id="container">

            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchResources.bind(this)}
              hasMore={this.state.hasMoreItems}
              loader={loader}
              threshold={100}
              initialLoad={true}
              element="ul"
              className="grid"

              getScrollParent={()=>document.body}
              useCapture={true}
            >
              <ResourceList
                  schema={schema}
                  resources={resources}
                handleItemSelect={this.handleItemSelect.bind(this)}
                handleItemEnter={this.handleItemEnter.bind(this)}
              />
            </InfiniteScroll>

        </section>
      </div>
      </React.Fragment>
    );
  }
}




export default Archive;
