import React, { Component } from 'react';
import {Graph2D} from 'polar-ui';

function clone(obj){
  return JSON.parse(JSON.stringify(obj));
}

class ResourceGraph extends Component {

  constructor(props) {
    // console.log(1);
    super(props);
    this.state = {
      nodes:[],
      links:[],
    }
    // this.nodeids = [];

  }
  // shouldComponentUpdate(nextProps, nextState){
  //   if(!nextProps.tail || !nextProps.head){
  //     return false;
  //   }
  //
  // }
  componentWillReceiveProps(nextProps){

    if(nextProps.tail && nextProps.head) {
      var nodes = nextProps.tail;
      let graph = this.process(nextProps.head, nextProps.tail, nextProps.relatives);
      graph = this.clean(graph);
      if(graph.nodes && graph.links){
        this.setState({nodes: graph.nodes, links: graph.links})

      }

    }
  }


  clean(graph){

    let {nodes, links} = graph;

    let cleanNodes = [];
    let cleanLinks = [];
    let cleanNodeIds = [];
    let cleanConcatenatedLinkIds = [];

    if(nodes && Array.isArray(nodes)){


      for(let i = nodes.length-1; i>=0; i--){
        if(nodes[i].id && !(cleanNodeIds.indexOf(nodes[i].id)>-1)){
          cleanNodes.push(nodes[i]);
          cleanNodeIds.push(nodes[i].id);
        }
      }
    }
    // console.log("uniq", cleanNodes);
    // now we have unique;

    // console.log("dirtyLinks", links);
    if(links && Array.isArray(links)){
      links.forEach(link => {
        let source = link.source.id || link.source;
        let target = link.target.id || link.target;

        if(source && target && (cleanNodeIds.indexOf(source)>-1) && (cleanNodeIds.indexOf(target)>-1) ){
          //check for doubles
          if(!(cleanConcatenatedLinkIds.indexOf(source + target + "")>-1)) {
            cleanLinks.push(link);
            cleanConcatenatedLinkIds.push(source + target + "");
          }
        }
      });
    }
    let cleanGraph = {};
    if(nodes){
      cleanGraph.nodes = cleanNodes;
    }
    if(links){
      // console.log("cleanLinks", cleanLinks);
      cleanGraph.links = cleanLinks;
    }
    return cleanGraph;
  }

  process(head, tail, relatives){
    //converts head, tail, and relatives into nodes and links
    // console.log("started process");
    // console.log("relatives", relatives);


    let links = this.state.links;
    let nodes = [];
    nodes.push(...tail);

    // console.log("relatives", relatives);
    let nodeids = this.nodeids;
    let graphUpdates = {};
    if(Array.isArray(relatives)){
      //array of resources
      for(let i = 0; i<relatives;i++){
        graphUpdates = this.processObject(head, relatives[i]);
        if(graphUpdates){
          if(graphUpdates.nodes) {

            nodes.push(...graphUpdates.nodes)}

          if(graphUpdates.links) {links.push(...graphUpdates.links)}
        }
      }
    }else{
      //metadata
      graphUpdates = this.processRelatives(head, relatives);
      if(graphUpdates){
        if(graphUpdates.nodes) {


          nodes.push(...graphUpdates.nodes);
        }
        if(graphUpdates.links) {
          links.push(...graphUpdates.links)}
      }
    }
    return {nodes, links}
    // this.setState({nodes, links});
  }
  processRelatives(head, relatives){
    let links = [];
    let nodes = [];
    // console.log('processing object', relatives);
    if(typeof relatives !== 'object'){
      // console.log("not object");
      return;
    }
    Object.keys(relatives).forEach((key) => {
      if(Array.isArray(relatives[key])){
        relatives[key].forEach(relative => {
          //{name: lebanon}
          // console.log("relative", relative);
          // if(relative && !this.nodeAlreadyExists(relative)){
          nodes.push(relative);
          links.push(this.processLinks(key, relative, head));

        });

      }else {
        nodes.push(relatives[key]);
        links.push(this.processLinks(key, relatives[key], head));
      }
    });

    // console.log("end of processObject links", links);
    // links.forEach(link => {
    //
    // });
    return {nodes, links}
  }
  processLinks(key, relative, head){
    if(key && head && head.id && relative){

      if (!relative.id && relative.name){
        relative.id = relative.name;
      }
      let name = key;

      let source = head.id;
      let target = relative.id;
      if(key.startsWith("_")){
        name = name.substring(1);
        source = relative.id;
        target = head.id;
      }
      return {source, target, name};
    }else{
      // console.log("someting not right senior", {key, relative, head});
      return;
    }

  }
  // nodeAlreadyExists(node, nodeIds){
  //   return (this.nodeids.indexOf(node.id) !== -1);
  // }
  render() {
    // console.log("Rendering ResourceGraph. <this.props.head>:", this.props.head);
    const {onNodeHover, onNodeClick, onNodeDoubleClick, onNodeDoubleClickType, onLinkClick} = this.props;
    const {nodes, links} = this.state;
    // console.log("onNodeHover", onNodeHover)
    // console.log("onNodeClick", onNodeClick)
    // console.log("onNodeDoubleClick", onNodeDoubleClick)
    // console.log("onNodeDoubleClickType", onNodeDoubleClickType)
    // console.log("onLinkClick", onLinkClick)

    // console.log("lliiinkkk",links);
    //handleClick={this._handleClick.bind(this)}
    return (
      <Graph2D
        data={clone({nodes, links})}
        onNodeClick={(node)=>onNodeClick(node)}
        onNodeDoubleClick={onNodeDoubleClick}
        onNodeDoubleClickType={onNodeDoubleClickType}
        onNodeHover={onNodeHover?(node) => onNodeHover(node):null}
        onLinkClick={(link)=>onLinkClick(link)}
      />

    );
  }
}

export default ResourceGraph;


/*
// was in line 52
addNode({id, name:title, type:"resource", val:"5", color:"#2d2d2d"});


// if(date) {
//   addNode({id: date, name: date, type:"date", color:"#727272"});
//   links.push({source: id, target: date, name: 'date'});
// }
// addNode({id: date, name: date});
childNodes.forEach((childNode) => {
  if(childNode.type){
    if(resource[childNode.type]){
      if(Array.isArray ( resource[childNode.type] )){
        resource[childNode.type].forEach((child) => {
          child.type = childNode.type;
          child.color = childNode.color;
          addNode(child);
          links.push({source: id, target: child.id, name: childNode.type});
        })
      }else{
        let child = {
          name: resource[childNode.type],
          id: resource[childNode.type],
          type: childNode.type,
          color: childNode.color
        }

        addNode(child);
        links.push({source: id, target: child.id, name: childNode.type});
      }

    }
  }

})

*/
