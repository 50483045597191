import React, { Component } from 'react';
import {Logo} from 'polar-ui';
import {NavLink} from 'react-router-dom';
import './index.css';
class Home extends Component {

  render(props) {
    return (
      <div className="container home">
        <div className="article">
          <div className="section">

            <strong>
              Ashkal Alwan Audiovisual Archive
            </strong>
            <p>
            The Ashkal Alwan archive ( <strong>aa</strong>archive ) is an invaluable resource for any scholar, artist, and researcher with an interest in the history of contemporary art and discursive practices in the region. An open and active repository of knowledge sharing and research, aaarchive aims to activate Ashkal Alwan’s unique multimedia material as a space for discovering, learning, and sharing, at a moment of rising displacement among Arabic speakers.
            </p>
            <p>
            Over the span of three decades, Ashkal Alwan has been accumulating a large and diverse audiovisual archive containing magnetic and digital tapes, DVDs, and digital files. This material is largely comprised of films and performances, recordings of previous editions of Home Works Forum, Home Workspace Program lectures, and numerous seminars and talks.

            Ashkal Alwan is now making available an open-access online platform that hosts the association’s extensive archive, giving the public access to Terabytes of digital recordings.
            </p>

            <p>
            Having been dispersed across several media, the efforts to consolidate Ashkal Alwan’s archive began effectively in 2015. In December 2015, aaarchive was launched on pan.do/ra, a free open-source media archive platform that allowed the association to manage large, decentralised collections of video material. In order to move the archive beyond our facilities and make them accessible online, Ashkal Alwan built its own platform.
            </p>
            <p>
            This platform is conceived as a space where users are encouraged to browse content and access different databases through a single intuitive search tool. <strong>aa</strong>archive remains a unique space, and the only sizeable publicly-accessible archive of art, performance-based, and discursive practices in the region.
            </p>
          </div>

          <div className="section arabic">
          <strong>
            أرشيف أشكال ألوان السمعي-البصري
          </strong>
            <p>
            يوفر أرشيف أشكال ألوان  ( <strong>aa</strong>archive ) مادة قيّمة لكل دارس وباحث وفنان معنيّ بتاريخ الممارسات الفنيّة المعاصرة في المنطقة. وهكذا يسعى أرشيف أشكال ألوان <strong>aa</strong>archive لإحياء تلك المادة السمعية والبصرية النادرة، وتحويلها إلى مساحة مفتوحة على الجمهور تسمح بالاكتشاف والتعلّم والمشاركة، وذلك في لحظة يتعرّض فيها متحدّثي العربية لحالة نزوح جماعي.
            </p>
            <p>
            عهدت أشكال ألوان على مدار العقود الثلاثة الماضية على جمع أرشيف سمعي-بصري متّسع ومتنوّع، ضم شرائط فيديو ودي ڤي دي وملفات رقمية، أغلبها أفلام وتسجيلات لعروض ومحاضرات قُدّمت في سياق «منتدى أشغال داخلية» أو «برنامج فضاء أشغال داخلية» وغيرها من الملتقيات.

            تقدّم أشكال ألوان اليوم منصّة مفتوحة للجمهور، تحوي أرشيف المؤسسة الواسع الذي يضم كميّة ضخمة من التسجيلات.
            </p>


            <p>
            بعد أن كان موزعاَ على عدد من الوسائط المتنوعة، انطلقت جهود حفظ المادة الأرشيفية وتنسيقها على نحو جاد في العام ٢٠١٥، وفي كانون الأول من العام نفسه تم تطوير منصة pan.do/ra وهي منصة مفتوحة معنيّة بأرشيفات المواد السمعية البصرية تتيح عدداً كبيراً من إمكانات التصفُّح والمشاهَدة وإدخال البيانات الوصفية على محتوى الأرشيف. لاحقاً، قامت أشكال ألوان بتطوير منصّتها الخاصة لإطلاقها على شبكة الإنترنت للجمهور العريض.
            </p>

            <p>
            تم تطوير هذه المنصة بغرض أن يكون مساحة تشجّع المستخدمين على تصفّح محتوى الأرشيف واكتشاف أعمالاً جديدة من خلال محرّك تصفّح ممتاز. نسعى لأن يظل <strong>aa</strong>archive منصّة رائدة مفتوحة للجمهور، لفنون وعروض وفكر منطقتنا.
            </p>
          </div>
        </div>
        <NavLink className="explore" to="/explore/">Explore the archive</NavLink>
      </div>


    );
  }
}

export default Home;
