import {API_URL} from './config';
import authentication from './authentication';

const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class API {
  // static async getResource(user = {}, data = {}) {
  //   return axios({
  //     method: 'post',
  //     url: API_URL+'/resource/get',
  //     headers: {'Content-Type': 'application/json; charset=utf-8',},
  //     data: {
  //       user,
  //       data,
  //     }
  //   })
  //   .then(function (response) {
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     alert(error)
  //   });
  //
  // }

  // static async getResources(user = {}, data) {
  //   let token = null;
  //   const headers = {
  //     'Content-Type': 'application/json; charset=utf-8',
  //   };
  //   try {
  //    let token = authentication.getToken();
  //    headers.authorization =  `Bearer ${token}`
  //  }catch(e){
  //    authentication.onDeauthentication();
  //  }
  //   return axios({
  //     method: 'get',
  //     url: API_URL+'/resources',
  //     headers,
  //     params: {
  //       user,
  //       data,
  //     }
  //   })
  //   .then(function (response) {
  //
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }

  // static async getResources(user = {}, data) {
  //   const headers = {'Content-Type': 'application/json; charset=utf-8',};
  //   return axios({
  //     method: 'get',
  //     url: API_URL+'/resources',
  //     headers,
  //     params: {
  //       user,
  //       data,
  //     }
  //   })
  //   .then(function (response) {
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }

  static async search(user = {}, model, page, limit, order_by) {
    if(!order_by) {
      order_by = [
        { "year": "DESC" },
        { "creators": { name: "ASC"} },



      ]
    }
    let data = {model, page, limit, order_by};

    return axios({
      method: 'post',
      url: API_URL+'/resource',
      headers: {'Content-Type': 'application/json; charset=utf-8',},
      data: {
        data,
        user,
      }
    })
    .then(function (response) {return response;})
    .catch(function (error) {console.log(error);});
  }

  static async getSchema(user = {}) {
    return axios({
      method: 'post',
      url: API_URL+'/resource/schema',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: {
        user
      }
    })
    .then(function (response) {return response;})
    .catch(function (error) {console.log(error);});
  }






  // static async setResource(user = {}, data={}) { with authentication
  // static async setResource(user = {}, data={}) {
  //   const token = authentication.getToken();
  //   return axios({
  //     method: 'post',
  //     url: API_URL+'/resources/set',
  //     headers: {
  //       // 'Access-Control-Allow-Origin': '*',
  //       'authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     data: {
  //       data,
  //       user,
  //     }
  //   }).then(function (response) {
  //     return response;
  //   })
  //   .catch(function (error) {
  //     //check if err status is 403 (forbidden) -> take him to login page
  //     console.log(error);
  //   });
  // }

  //without authentication
    static async setResource(user = {}, data={}) {
      // const token = authentication.getToken();
      // console.log("user", user);
      // console.log("data", data);

      return axios({
        method: 'put',
        url: API_URL+'/resource',
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          // 'authorization': `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
        // always send data : data
        data: {
          data
        }
      }).then(function (response) {
        if(response && response.data){
          
          return response.data;
        }

      }).catch(function (error) {console.log(error);});
    }
  // get each form select options separatly
  // static async getProjects(user = {}, data = {}) {
  //   return axios({
  //     method: 'post',
  //     url: API_URL+'/projects/get',
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     data: {
  //       data,
  //       user
  //     }
  //   })
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }
  // static async getCreators(user = {}, data = {}) {
  //   return axios({
  //     method: 'post',
  //     url: API_URL+'/creators/get',
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     data: {
  //       data,
  //       user
  //     }
  //   })
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }
  // static async getSubjects(user = {}, data = {}) {
  //   return axios({
  //     method: 'post',
  //     url: API_URL+'/subjects/get',
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     data: {
  //       data,
  //       user
  //     }
  //   })
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }



  static async triggerResource(user = {}, source = {}, thumbnailUrl) {

    return axios({
      method: 'post',
      url: API_URL+'/resource/trigger',
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: {
        user,
        source,
        thumbnailUrl,
      }
    })
        .then(function (response) {

          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

  }
}
