import React, {Component, Fragment} from 'react';
import ResourceItem from '../ResourceItem';
import {Grid} from 'polar-ui';

class ResourceList extends Component {


  render() {
    let {schema, resources} = this.props;
    let selectedItem = this.props.selectedItem;
    let gridItems = null;
    let selected = false;
    const {handleItemSelect, handleItemEnter} = this.props;
    if(resources){
      gridItems = resources.map((resource) => {
        if(resource){
          if(resource.id && resource.id === selectedItem){
            selected = true;
          }
          return (
            <li key={resource.id}>
              <ResourceItem
                  schema={schema}
                  selected={selected}
                  data={resource}
                  handleItemSelect={handleItemSelect?handleItemSelect.bind(this):null}
                  handleItemEnter={handleItemEnter?handleItemEnter.bind(this):null}
              />
            </li>
          );
        }


      });
    }
    return (

      // <Grid >
      //   {gridItems}
      // </Grid>
      <Fragment>
        {gridItems}
      </Fragment>

    );

  }

}



export default ResourceList;
