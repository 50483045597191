import {API_URL} from './config';
import authentication from './authentication';
const crypto = require('crypto');

const axios = require('axios');
// const bcrypt = require('bcrypt');
axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class API {

  static async login(credentials={}) {
    const {username, email} = credentials;
    const password = crypto.createHash('sha256').update(credentials.password).digest('base64');
    let data;
    if(username){
      data = {
        username,
        password
      }
    }
    if(email){
      data = {
        email,
        password
      }
    }

    return axios({
      method: 'post',
      url: API_URL+'/login',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data
    })
    .then(function (response) {
      if(response.data.token){
        authentication.onAuthenticate(response.data);
      }
      return response;
    })
    .catch(function (error) {
      console.log("err",error);
      throw error;
    });
  }
  static async logout() {

    let token = null;
    try {
      token = authentication.getToken();
    } catch(e) {
      console.log(e);
      return;
    }

    return axios({
      method: 'post',
      url: API_URL+'/logout',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': `Bearer ${token}`
      },
      data: {}
    })
    .then(function (response) {
      authentication.onDeauthentication();
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

    //redirect to
  }

}
