import React, { Component } from 'react';
import Request from 'react-http-request';
import {List, ListItem, Loading, Pane, ItemDescription, SocialIcons} from 'polar-ui';
import ResourceList from '../../resources/ResourceList';
import ResourceDescription from '../../resources/ResourceDescription';
import ResourceGraph from '../../resources/ResourceGraph';
import ResourcesAPI from '../../../api/resources';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchTools from '../../../api/search';

import './index.css';



class ArchiveGraph extends Component {

  constructor(props) {
    // console.log(1);
    // C673DB pink
    // FC6B55 pink
    // ff968c light pink
    // 9CB8BE light blue grey
    // FA472C bright orange
    // FC6B55 ok orange
    // 708A6E army green
    // CEAB7D army beige
    // CBBCB1 white-ish?
    super(props);
    // const palette = ["#ff968c", "#9CB8BE", "#CEAB7D", "#708A6E", "#CBBCB1", "#eee", "#454545"];
    // const palette = ["#838383", "#00A8B3", "#10A2CE", "#97d168", "#ff8b73", "#f3b24c", "#F1F1F2", "#444"];
    const palette = ["#ff974c", "#665a5c", "#da6254", "#8f5c4f", "#000", "#000", "#000", "#000"];
    /*
    RAL 1035
    RAL 4008
    RAL 4011
    RAL 5014
    RAL 5012
    RAL 5022
    RAL 5023
    RAL 5024


//


    */
    this.onNodeDoubleClickType = "resource";
    this.state = {
      resource: {},
      selectedResource:{},
      resources:[],
      // relatives: {},
      // head:{},
      // tail:[],
      selectedItem:"",
      header:"all",
      meta: [
        {type: 'resource', color: "#41474d", name_property:'title', size:'large', text_color:'white'},
        {type: 'date', color: "#f4f5f0", text_color:'#666'},
        {type: 'creator', color: "#bdb7b1", text_color:'white'},
        // {type: 'actor', color: "#b0a198"},
        {type: 'director', color: "#bdb7b1", text_color:'white'},
        {type: 'publisher', color: "#bdb7b1", text_color:'white'},
        {type: 'country', color: "#d4d5d4", text_color:'#333'},
        {type: 'location', color: "#d4d5d4", text_color:'#333'},
        {type: 'subject', color: "#9e978e", text_color:'white'},

        {type: 'project', color: "#1fcc9f", name_property:'title', text_color:'white', icon:(<FontAwesomeIcon icon="layer-group"/>)},

      ],

    }

    this.fetchResource = this.fetchResource.bind(this);
    this.pane = React.createRef();
    this.initialize();
  }

  componentDidMount() {
    document.querySelector('.viewport').classList.add('no-scroll');
    document.querySelector('.footer').classList.add('true');
    document.querySelector('.footer').classList.remove('false');
  }
  componentWillUnmount() {
    document.querySelector('.viewport').classList.remove('no-scroll');
    document.querySelector('.footer').classList.remove('true');
  }

  async initialize(){
    // console.log("initializing..");
    const {match: {params: {id}}} = this.props;
    if(id){
      // console.log("initialize id", id);
      //fetch and add to tail
      let resource = await this.fetchResource({publicId:id});

      if(resource) {
        let centrifuged = this.centrifuge(resource);
        let head = centrifuged.node;
        // head = this.addMeta(head, {type: 'resource', color:'#2e97fc', text_color:'#fff', name_property:'title'});
        head = this.addMeta(head, {current: true, border_color:'#2e97fc'});

        let tail = [head]

        this.setState({resource, head, tail, relatives: centrifuged.relatives});
        // console.log("initial this.state.head", this.state.head);
        // console.log("initial this.state.tail", this.state.tail);
        // console.log("initial this.state.relatives", this.state.relatives);
      }


    }
  }
  centrifuge(resource) {
    let node = {};
    // console.log("centrifuging: ", resource);

    if(resource.id){node.id = resource.id}
    if(resource.publicId){node.publicId = resource.publicId}
    if(resource.name){node.name = resource.name}
    else if(resource.title){node.name = resource.title}
    node.type = "resource";



    this.state.meta.forEach(metaItem => {if(metaItem.type && metaItem.type===node.type){
      node = this.addMeta(node, metaItem);
    }})

    let metadata = this.sanitize(resource, this.state.meta);
    // console.log("node", node);
    // console.log("metadata", metadata);
    return {node, relatives:metadata};

  }
  node(id, name, type){
    return {id, name, type};
  }

  async fetchResource(node) {
    // console.log("in fetchResource. node: ", node);
    // const id = (typeof node === 'string') ? node : node.id;
    const model = {};
    if(node && node.publicId){
      // console.log("has publicId");
      model.publicId = node.publicId;
    }else if(node && node.id){
      // console.log("has not a publicId");
      model.id = node.id;
    }else if(typeof node === 'string'){
      model.id = node;
    }

    if(model.id || model.publicId){

      // return ResourcesAPI.search({}, resource)
      //   .then(res => {
      //     if(!res.data){
      //       throw new Error('could not find data in res for node: ' + node)
      //     }
      //     // returns object {title:.., creators:.. }
      //     // console.log("in fetchresource", this.state.resource);
      //     return res.data;
      //   }).catch((e) => {
      //     console.log('Error in fetchResource', e);
      //   });
      ResourcesAPI.search({}, model)
          .then(res => {

            if(res.data.length == 1) {
              let resource = res.data[0];
              return resource;
            }
          })
          .catch(e => {console.log(e)});
    } else {
      // return Promise.reject(new Error('no id'))
    }
  }
  async fetchRelatives(head){
    let id = head.id;
    let relatives;
    // takes an object id, outputs all the relatives of that object
    let resource = await this.fetchResource(id);

    if(resource){
      // gets the resource object with the relatives in this.state.resource
      // sanitize: keep only the relatives

      relatives = this.sanitize(resource, this.state.meta);

      // save in
    }else{
      // get all resources[] associated with this metadata

      // sanitize: leave only id and title
      relatives = await this.handleSearch(id);

      // put the resources in an object under select element's inverse(_)data type (ex. _date ):
      let pure = [];
      relatives.forEach(relative => {
        pure.push(this.centrifuge(relative).node);
      });
      relatives = this.reconcile(pure, head);

    }
    return {resource, relatives};
  }
  sanitize(resource, meta){
    let sanitized = {};
    for(let i = 0; i<meta.length;i++){
      if (resource.hasOwnProperty(meta[i].type)) {
        sanitized[meta[i].type] = this.addMeta(resource[meta[i].type], meta[i]);
      }
    }

    // console.log("sanitized", sanitized);
    return sanitized;
  }
  addMeta(input, metaItem){
    // accepts (1) set: an array, object, or var, and (2) meta: an object such as {type: 'date', color:'#777777', name_property:'date'}
    // fn returns an array or object with the original properties plus the metadata found in meta
    let output;
    if(Array.isArray(input)){
      output = [];
      input.forEach(obj => {
        output.push(this.addObjectMeta(obj, metaItem));
      });
    }else {
      output = this.addObjectMeta(input, metaItem);
    }
    return output;
  }

  addObjectMeta(input, metaItem){
    let output = {};
    // outpu {name, type, color, text_color}
    if(Array.isArray(input)){
      return this.addMeta(input, metaItem);
    }
    if(!(typeof input == 'object')){
      output.name = input;
    }else {
      output = JSON.parse(JSON.stringify(input));

      if(metaItem && metaItem.name_property && input[metaItem.name_property]){
        output.name = input[metaItem.name_property];
      }
    }
    if(metaItem){
      if(metaItem.type){output.type = metaItem.type;}
      if(metaItem.color){output.color = metaItem.color;}
      if(metaItem.color){output.color = metaItem.color;}
      if(metaItem.text_color){output.text_color = metaItem.text_color;}
      if(metaItem.size){output.size = metaItem.size;}
      if(metaItem.border_color){output.border_color = metaItem.border_color;}
      if(metaItem.current){output.current = metaItem.current;}
      if(metaItem.icon){output.icon = metaItem.icon;}
    }
    return output;
  }
  reconcile(resources, head){

    let relatives = {};
    relatives["_" + head.type] = resources;
    return relatives;
  }
  getSearchTerms(p) {
    const search = p.location?p.location.search:null; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    return params.get('keywords'); // bar
  }
  handleSearch(terms) {
    if(terms){
      // this.setState({header:terms, loading: true});
      terms = SearchTools.parse(terms);

      // terms = terms.split(" ");
      let model = {$terms: terms};
      let page = 1
      ResourcesAPI.search({}, model, page)
        .then(res => {
          if(!res.data){
            throw new Error('could not find data in res for node: ' + terms)
          }
          return res.data;
        }).catch((e) => {
          console.log('Caught an error during the search', e);
        });

    }
    return;
  }

  getMetaItem(obj, meta){
    let output;
    if(obj.type){
      meta.forEach(metaItem=>{
        if(metaItem.type && (metaItem.type === obj.type)){
        output = metaItem;
      }});
    }
    return output;
  }
  printLegend(meta){
    // console.log("metaaa", meta);

    const legend = meta.map((metaItem) =>
    <li className={(this.state.head && this.state.head.type && this.state.head.type===metaItem.type)?"active":"dormant"}>
      <svg height="24" width="24"><circle cx="12" cy="12" r="11" fill={metaItem.color} /></svg>
      <span>{metaItem.type}</span>
    </li>);

    return legend;
  }
  printTooltip(node){
    // console.log("metaaa", meta);
    if(node) {
      let tip, cleanup, reset, tooltip;

      if(node.type==="resource"){

        tip = (
          <li className="graph-tooltip-item">
            <NavLink to={`/resource/${this.state.head.publicId}`}> <strong className="clear-filter">Watch</strong></NavLink>
          </li>
        );
        cleanup = (
          <li className="graph-tooltip-item">
            <NavLink exact to={`/explore/graph/${this.state.head.publicId}`}> <strong className="clear-filter">Clean</strong></NavLink>
          </li>
        );
      }

      reset = <NavLink exact to={`/explore/graph/${this.state.tail[0].publicId}`}> <strong className="clear-filter">Reset</strong></NavLink>

      tooltip = (
        <ul className="graph-tooltip-box">
        {tip}
        {cleanup}

        <li className="graph-tooltip-item">
          {reset}
        </li>
        </ul>
      );

      return tooltip;
    }
    return;


  }
  async onLinkClick(link){

    // let source, target;
    // if(link && link.source && link.source.id && link.target && link.target.id) {
    //   source = link.source.id;
    //   target = link.target.id;
    // }
    //
    // for(let i=0; i<this.state.tail.length;i++) {
    //   if(this.state.tail[i].id===(source||target) && this.state.tail.length>1){
    //     alert("Found the source" + source);
    //     this.setState({tail: this.state.tail.splice(0, i)})
    //   }
    // }
  }
  async onNodeClick(node){
    // 4 things should happen:
    // add new node to head
    // push it to tail
    // fetch resource
    // generate relatives
    //
    let head;
    let tail = this.state.tail;
    let resource;
    let relatives;
    // console.log("clicked node", node);
    if(node && node.id && node.name){


      let {id, publicId, name, type} = node;
      head = {id, publicId, name, type};
      alert("about to await this.fetchRelatives(head);")
      let fetched = await this.fetchRelatives(head);
      // head = this.addMeta(head, {color:'#2e97fc', text_color:'#fff', current:true});
      head = this.addMeta(head, {text_color:'#fff'});

      // tail = this.addToTail(head);

      let headMetaItem = this.getMetaItem(head, this.state.meta);
      head = this.addMeta(head, headMetaItem);
      head = this.addMeta(head, {border_color:'#2e97fc', current:true});
      //remove border from last item


      let metaItem = this.getMetaItem(tail[tail.length-1], this.state.meta);
      tail[tail.length-1] = this.addMeta(tail[tail.length-1], metaItem);
      if(tail[tail.length-1].current) {
        delete tail[tail.length-1].border_color;
        delete tail[tail.length-1].current;
      }
      tail.push(head);

      this.setState({head, tail, relatives: fetched.relatives, resource: fetched.resource});
    }
  }
  onNodeDoubleClick(node) {
    if(node.publicId && node.type && node.type === this.onNodeDoubleClickType){
      this.props.history.push(`/resource/${node.publicId}`)
    }

  }
  //
  render() {
    const loader = <div className="loader">...</div>;
    const {resource, head} = this.state;

    let source = resource && resource.sources && resource.sources.length > 0 ?  resource.sources[0] : undefined;
    let thumbnail = source ? source.thumbnail : undefined

    // console.log("rendering Archive Graph:", {head: this.state.head, tail:this.state.tail, relatives: this.state.relatives});
    //           {(this.state.head && this.state.head.type === 'resource')? <NavLink to={`/resource/${this.state.head.publicId}`}> <strong className="clear-filter"> watch </strong></NavLink>:''}

    return (
      <React.Fragment>
      <Pane ref={this.pane} className={resource?'active':''} thumbnail={thumbnail}>
        <ItemDescription>
          <ResourceDescription data={resource}  granularity="2" api="/explore/"/>
        </ItemDescription>
      </Pane>
      <div className="ghost"></div>
      <div className="container scootch">
        <header>
          <span>Focus: <strong>{this.state.head?this.state.head.name:''}</strong>
          </span>
        </header>
        <header className="graph-legend">
          <ul >{this.printLegend(this.state.meta)}</ul>
        </header>
        {this.printTooltip(head)}


        <section className="graph">
          <ResourceGraph
            head={this.state.head}
            tail={this.state.tail}
            relatives={this.state.relatives}
            onLinkClick={this.onLinkClick.bind(this)}
            onNodeClick={this.onNodeClick.bind(this)}
            onNodeDoubleClick={this.onNodeDoubleClick.bind(this)}
            onNodeDoubleClickType={this.onNodeDoubleClickType}
          />
        </section>
      </div>
      </React.Fragment>
    );
  }
}

//onNodeHover={this.onNodeClick.bind(this)}


export default ArchiveGraph;
