const LOCALSTORAGE_KEY = 'token';

class Authentication {
  constructor(user) {
    if(user){
      this.onAuthenticate(user);
    } else {
      this.authenticated = false;
    }
  }
  onAuthenticate(user) {
    console.log('onAuthenticate', user);
    this.authenticated = true;
    this.user = user;
    setUser(user);
  }
  onDeauthentication(){
    this.authenticated = false;
    this.user = null;
    clearUser();
  }
  getUser(){
    if(this.authenticated){
      return this.user;
    } else {
      return null;
    }
  }
  getToken(){
    if(this.authenticated){
      return this.user.token;
    } else {
      throw new Error('requires_auth');
    }
  }
}

function clearUser() {
  window.localStorage.removeItem(LOCALSTORAGE_KEY);
}

function setUser(user) {
  window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(user));
}

function getUser() {
  return window.localStorage.getItem(LOCALSTORAGE_KEY) && JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY));
}
const authentication = new Authentication(getUser());

export default authentication;
