import React, { Component } from 'react';
import AnArcticArchive from '../../logos/AnArcticArchive';
import './index.css';

class ArcticArchiveFooter extends Component {

  render(props) {
    //        <AnArcticArchive />

    return (
      <div className="arctic-archive-footer">


      </div>

    );
  }
}

export default ArcticArchiveFooter;
