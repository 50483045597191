import React, { Component } from 'react';
import EmbeddedVideo from '../../resources/EmbeddedVideo';

class ResourceContent extends Component {
  constructor(props) {
    super(props);
  }
  render(props) {
    let content = "";
    // if(this.props.media.toLowerCase() === "video"){
      content = (
        <EmbeddedVideo
        embedUrl={this.props.embedUrl}
        medium={this.props.medium}
        identifier={this.props.identifier}
        />);
    // }
    return content;
  }
}

// ResourceContent.defaultProps = {
//   media:"",
//   embedUrl: "",
//   identifier:"",
//   medium:"",
//
// };

export default ResourceContent;
