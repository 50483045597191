import React, { Component } from 'react';
import {Logo} from 'polar-ui';
import {NavLink} from 'react-router-dom';

import './index.css';

const alt = "an arctic archive";

class AnArcticArchive extends Component {

  render(props) {
    const logo_main = "https://storage.googleapis.com/arcticarchive/logos/anarcticarchive-white.png";
    const logo_hover = "https://storage.googleapis.com/arcticarchive/logos/anarcticarchive-blue.png";
    return (
      <a className="logo" href="/" >
        <Logo alt={alt} logo_main={logo_main} logo_hover={logo_hover} height="12"/><span className="beta-logo">β</span>
      </a>

    );
  }
}

export default AnArcticArchive;
