import {API_URL} from './config';
import authentication from './authentication';
// google: header authorization bearer

const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class API {
  //fix this class.. TODO: code redundancy



  static async getForm(data = {}) {
    let user = {};
    return axios({
      method: 'post',
      url: API_URL+'/forms',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: {
        data
      }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  }




}
