import React, { Component } from 'react';
import {Logo} from 'polar-ui';
import {NavLink} from 'react-router-dom';
import logo_main from './aaa-grey-275.png';

const alt = "ashkal alwan archive";

class AshkalAlwanArchiveLogo extends Component {

  render(props) {
    let height = 17;
    if(this.props.height){
      height = this.props.height;
    }
    return (

        <Logo alt={alt} logo_main={logo_main} logo_hover={logo_main} height={this.props.height} nav={"/about"}/>

    );
  }
}

export default AshkalAlwanArchiveLogo;
