import React, { Component } from 'react';


class EmbeddedVideo extends Component {

  render(props) {
    let content = "Content Unavailable";
    if(this.props.medium.toLowerCase() === "vimeo"){
        content = (
          <iframe src={this.props.embedUrl}
            frameborder="0"
            height="320"
            width="640"
            allowFullScreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            ></iframe>);
    }else if(this.props.medium.toLowerCase() === "youtube"){
        content = (
          <iframe
            
            src={this.props.embedUrl}
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            ></iframe>
        );
    }
    return content;
  }
}
//if(data.source && data.source.embedUrl && data.source.identifier && data.source.medium && data.source.medium.name) {
//if(data.source.medium.name.toLowerCase() === "vimeo"){

EmbeddedVideo.defaultProps = {
  embedUrl: "",
  identifier:"",
  medium:"",

};

export default EmbeddedVideo;
