import React, { Component } from 'react';
import {Search} from 'polar-ui';
import { withRouter } from 'react-router-dom';

class ArchiveSearch extends Component {
  handleSearch(terms) {
    if(terms){
      this.setState({header:terms, loading: true});
      // terms = terms.replace(/ /g, '+');
      // terms = terms.replace('&', '%26');
      terms = encodeURIComponent(terms);
      this.props.history.push('/explore/?keywords='+terms);
    }
  }
  render(props) {

    return (
      <Search handleSearch={this.handleSearch.bind(this)} />
    );
  }
}

export default withRouter(ArchiveSearch);
