import React, { Component } from 'react';
import {Pane} from 'polar-ui';
import ResourcesAPI from '../../../api/resources';
import TAPI from '../../../api/thumbnail';
import {ItemDescription} from 'polar-ui';
import ResourceDescription from '../../resources/ResourceDescription';
import ResourceContent from '../../resources/ResourceContent';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft, faPlay
  } from '@fortawesome/free-solid-svg-icons';


import './wave.svg';
import './index.css';
library.add(faChevronLeft);

class Resource extends Component {
  constructor(props){
    super(props);
    this.state = {
      play : false,
    };
    this.onClickPlay = this.onClickPlay.bind(this);
    this.fetchResource();

  }
  onClickPlay() {
    this.setState({play:true});
  }
  fetchResource() {
    const {match: {params: {id}}} = this.props;
    let model = id ? {publicId: id} : {};
    ResourcesAPI.search({}, model)
        .then(res => {
          const hits = res.data.hits;
          if(hits.length == 1)
            this.setState({data: hits[0]});
          // this.setState({data: res.data[0], selectedItem:id});

          this.updateThumbnail(hits[0]);
        })
        .catch(e => {console.log(e)});
  }
  async updateThumbnail(data) {
    let source;
    if(data && data.sources && data.sources[0]){
      source = data.sources[0];
    }
    if(source && source.embedUrl && source.identifier && source.medium && source.medium.name) {
      let thumbnailUrl;
      // get new thumbnailUrl
      if (source.medium.name === "vimeo") {
        thumbnailUrl = await TAPI.getThumbnail_Vimeo(source.identifier);
      }else if (source.medium.name === "youtube") {
        thumbnailUrl = TAPI.getThumbnail_YouTube(source.identifier);
      }
      ResourcesAPI.triggerResource({}, source, thumbnailUrl)
      .then(res => {
      })
      .catch(e => {console.log(e)});
    }
  }

  render() {
    window.scrollTo(0,0);
    let title = "...";
    let content = "";
    if(this.state.data){
      let data = this.state.data;
      let source;
      if(data.sources && data.sources[0]){
        source = data.sources[0];
      }
      if(data.title) {
        title = data.title;
      }
      // Replace when media is fixed in p2g
      // make sure that
      // match (e:RESOURCE)-[:source]-() return count(e)
      // and
      // match (e:RESOURCE)-[:media]-() return count(e)
      // return the same number of resulta
      //
      let {play} = this.state;
      if (!(source && source.thumbnailUrl)) {
        play = true;
      }
      if(play && source && source.embedUrl && source.identifier && source.medium && source.medium.name) {

        content = (<ResourceContent embedUrl={source.embedUrl} medium={source.medium.name} identifier={source.identifier} />);
      }else if (source && source.thumbnailUrl) {
        //          //style={{ backgroundImage : `url(${source.thumbnailUrl})`}}

        content =  (
          <div onClick={this.onClickPlay.bind(this)} className="resource-content-preview" >
            <img src={source.thumbnailUrl} onError={(e)=>{e.target.style='display:none;'}}/>
            <div className="mask" >
              <i>
                <FontAwesomeIcon icon="play"/>
              </i>
            </div>
          </div>
        ) ;



      }else{

        content = ("...")
      }
    }
    return (
      <div className="container full-width">


        <section className="resource-content">
          {content}
        </section>



        <div className="resource-description">

          {this.state.data? (
            <ItemDescription>
              <ResourceDescription data={this.state.data} granularity="3" api="/explore/"/>
            </ItemDescription>
          ) : "..."}
        </div>
        <section>


        </section>
      </div>
    );
  }
}

export default Resource;
