// import {API_URL} from './config';

// const axios = require('axios');

// axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class Search {

  static parse(input) {
    //handles arrays and string input, not objects
    let terms = input;
    let splitTerms = terms.match(/(".*?"|[^"\s]+)+(?=\s*|\s*$)/g);
    // var R =  /(\w|\s)*\w(?=")|\w+/g;
    // var splitTerms = terms.match(R);
    for (let i in splitTerms) {
      splitTerms[i] = splitTerms[i].replace(/^"(.+(?="$))"$/, '$1');
    }

    if(typeof terms === 'string' || terms instanceof String){
      terms = terms.split(" ");
    }
    //now terms is an array
    // if(terms.length == 1) {
    //   //one word
    //   let word = terms[0];
    //   if(word.length == 4 && !isNaN(word)){
    //     return {year: word};
    //   }
    // }

    // return terms;
    return splitTerms;
  }


}
