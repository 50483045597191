import React, { Component } from 'react';
import {Logo, Form, FormSet, Toast, Select, MediaForm} from 'polar-ui';
// import {NavLink} from 'react-router-dom';
import RJSF from "react-jsonschema-form";

import RAPI from '../../../api/resources';
import FAPI from '../../../api/forms';

import './index.css';
class AddResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    //
    // this.fetchProjects();
    // this.fetchCreators();
    // this.fetchSubjects();
    this.fetchForm();
    this.fetchResourceSchema();
  }
  async submit(key, formData){

    const pair = {};
    pair[key] = formData;

    this.setState({formData: pair});
  }

  //
  // async fetchProjects(){
  //   let projects;
  //   await RAPI.getProjects({}, {})
  //   .then(res => {
  //     this.setState({
  //       existingProjects: res,
  //     })
  //   })
  //   .catch(e => {console.log(e)});
  //   return projects;
  // }
  // async fetchCreators(){
  //   let creators;
  //   await RAPI.getCreators({}, {})
  //   .then(res => {
  //     this.setState({
  //       existingCreators: res,
  //     })
  //   })
  //   .catch(e => {console.log(e)});
  //   return creators;
  // }
  // async fetchSubjects(){
  //   let subjects;
  //   await RAPI.getSubjects({}, {})
  //   .then(res => {
  //     this.setState({
  //       existingSubjects: res,
  //     })
  //   })
  //   .catch(e => {console.log(e)});
  //   return subjects;
  // }
  async fetchForm(){
    try{
      const form = await FAPI.getForm({title: "Add Resource"});
      this.setState({
        form,
      });
    } catch(e) {
      console.log(e);
    }
  }
  async fetchResourceSchema(){
    try{
      const resourceSchema = await FAPI.getResourceSchema({});
      this.setState({datatypeSchema:resourceSchema});
    } catch(e) {
      console.log(e);
    }
  }
  async onSubmit(formData){
    const setResourceResponse = await RAPI.setResource({}, formData);
    // TODO remove workaround below
    // workaround to route straight to archive explore, as if you searched for the resource that was just created
    if(setResourceResponse && setResourceResponse.validated){
      let publicId = setResourceResponse.validated.publicId;
      let title = setResourceResponse.validated.title;
      this.props.history.push(`/explore/?keywords=${publicId}&header=${title}`);
    }
    //
    return setResourceResponse;

  }

  render(props) {
    window.scrollTo(0, 0);

    // let {existingProjects, existingSubjects, existingCreators} = this.state;
    // console.log("existingProjects", existingProjects);
    // console.log("existingCreators", existingCreators);
    // console.log("existingSubjects", existingSubjects);



    //
    // const project_schema = {
    //   "title": "Project", "type": "object", "required": [],
    //   "properties": {
    //     "project": {
    //       "type": "string",
    //       "title": "Choose an existing project, or enter a new one",
    //       "enum": existingProjects
    //     }
    // }};
    // const title_schema = {
    //   "title": "Title", "type": "object", "required": ["title"],
    //   "properties": {
    //     "title": {"type": "string", "title": "Title"},
    // }};
    // let onSubmit = {}
    // onSubmit.project = ({formData}, e) => {
    //   return this.submit("project", formData);
    // }
    // onSubmit.title = ({formData}, e) => {
    //   return this.submit("title",formData);
    // }
    // onSubmit.creators = ({formData}, e) => {
    //   return this.submit("creators",formData);
    // }
    // onSubmit.subjects = ({formData}, e) => {
    //
    //   return this.submit("subjects",formData);
    // }
    //
    //
    //
    //


    // let validation;
    // let onSubmit = (formData, e) => {
    //   RAPI.setResource({}, formData)
    //   .then(response => {
    //     // validation = response;
    //
    //     return response;
    //
    //   })
    //   .catch(e => {console.log(e)});
    // }

    let {form, datatypeSchema} = this.state;

    // let {onSubmit} = this;
    if(form){
      let sections = form.sections;
    }
    let drivers = {
      default: RJSF,
      select: Select,
      media : MediaForm
    }
    let formData = {

    }
    let uiSchema ={
      "description": {
        "ui:widget": "textarea",
      }
    }
    return (
      <div className="container add-resource">
        <FormSet
          // validation={validation}
          form={form}
          datatypeSchema={datatypeSchema}
          formData={formData}
          uiSchema={uiSchema}
          onSubmit={(data)=>this.onSubmit(data)}
          drivers={drivers}
        >


        </FormSet>
      </div>

    );
  }
}

export default AddResource;
