import React, { Component } from 'react';

import {Main, Viewport, NavSide, NavTop, Footer, Line, Logo, UserCard, Toast} from 'polar-ui';


import './App.css';
// import './bootstrap.min.css';
import Home from './components/pages/Home';
import Archive from './components/pages/Archive';
import ArchiveGraph from './components/pages/ArchiveGraph';
import Resource from './components/pages/Resource';
import AddResource from './components/pages/AddResource';

import Login from './components/pages/Login';
import ArcticArchiveFrame from './components/ui/ArcticArchiveFrame/index.js';
import UAPI from './api/users';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCompass, faSearch, faTrash, faCodeBranch, faLock, faExclamationTriangle,
  faGlobe, faImages, faShare, faPlay, faLayerGroup,faSignOutAlt, faCog
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import authentication from './api/authentication';
library.add(faCompass);
library.add(faSearch);
library.add(faTrash);
library.add(faCodeBranch);
library.add(faLock);
library.add(faExclamationTriangle);
library.add(faGlobe);
library.add(faImages);
library.add(faShare);
library.add(faPlay);
library.add(faLayerGroup);
library.add(faSignOutAlt);
library.add(faCog);
//FontAwesome>

const navRoutes = [
  {path:"/about/", title:"about aaarchive", component:Home},
  {path:"/explore/", title:"the archive", component:Archive},
  {path:"/explore/:id", title:"archive", component:Archive, skipNav: true},
  {path:"/explore/graph/:id", title:"archive", component:ArchiveGraph, skipNav: true},
  {path:"/resource/:id", title:"resource", component:Resource, skipNav: true},
  // {path:"/login", title:"login", component:Login, skipNav: true},
  {redirect: true, from:"/", to:"/explore/", skipNav: true}
];
const configRoutes = [
  // {path:"/resource/add", title:"Add Resource", component:AddResource},

];
class App extends Component {
  async handleLogout(){
    console.log("logging out!");
    await UAPI.logout()
    .then(res => {
      Toast.setWarning("Successfully logged out");
      setTimeout(function(){window.location.reload()}, 4000);
    }).catch(e => {
      console.log(e.message);
      Toast.setWarning("Could not logout" + e.message);
    });
  }
  render() {
    // console.log('rendering');
    // <NavSide orientation="right" icon="lock"routes= {routes}/>
    const user = authentication.getUser();
    // alert(JSON.stringify(user));
    //  handleLogout={authentication.onDeauthentication()}
    //
    //
    //

    //   <div className="App">
    //     <ArcticArchiveFrame user={user}>
    //       <Viewport>
    //         <NavSide icon="compass" mask={"transparent"} routes= {navRoutes}/>
    //         <NavSide orientation="right" mask={"transparent"} icon="cog" routes={configRoutes}>
    //           {user?<UserCard user={user} handleLogout={this.handleLogout} />:''}
    //         </NavSide>
    //         <Main routes = {configRoutes.concat(navRoutes)}/>
    //       </Viewport>
    //     </ArcticArchiveFrame>
    //   </div>



    return (
      <div className="App">
        <ArcticArchiveFrame user={user}>
          <Viewport>
            <NavSide icon="compass" mask={"transparent"} routes= {navRoutes}/>
            <Main routes = {navRoutes}/>
          </Viewport>
        </ArcticArchiveFrame>
      </div>
    );
  }
}
// resource-content
// height: 207px





export default App;
