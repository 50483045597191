import {API_URL} from './config';
const axios = require('axios');
// import axios from 'axios';
// const crypto = require('crypto');

axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.adapter = require('axios/lib/adapters/http');
export default class Thumbnail {

  static async getThumbnail_Vimeo(identifier) {

    return axios({
      method: 'get',
      url: "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + identifier + "",
      headers: {
      }
    })
        
    .then(function (response) {
      const data = response.data;
      if(data && data.thumbnail_url) {
        return data.thumbnail_url;
      }else if(data && data.thumbnail_medium) {
        return data.thumbnail_medium;
      }
      return;
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  static getThumbnail_YouTube(identifier) {
    let url = "https://img.youtube.com/vi/" + identifier + "/maxresdefault.jpg";
    return url;
  }


}
