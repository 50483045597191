import React, { Component, Fragment } from 'react';
import AnArcticArchive from '../../logos/AnArcticArchive';
import SettingsAPI from '../../../api/settings';
import ArcticArchiveFooter from '../ArcticArchiveFooter';
import AshkalAlwanArchiveLogo from '../../logos/AshkalAlwanArchiveLogo';
import ArchiveSearch from '../../forms/ArchiveSearch';


import {NavSide, NavTop, Footer, Line, SocialIcons, Logo, Toast, UserNavTop, Modal} from 'polar-ui';


class ArcticArchiveFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings:{},
    }
    this.getArchiveSettings();
  }
  getArchiveSettings() {
    SettingsAPI.getArchiveSettings({}, {model: {name: 'aaarchive'}})
    .then(res => {
      if(res.data && res.data.hits && res.data.hits.length > 0) {
        this.setState({settings:res.data.hits[0]});
      }
    })
    .catch(e => {console.log(e)});
  }
  render() {
    const {children, user} = this.props;
    // console.log('rendering', user);
    //<UserNavTop username={user && user.username}/>
    // alt={this.state.settings.name}
    //
    // <Modal />
    return (
      <Fragment >
        <NavTop >
          <Logo alt="" logo_main={this.state.settings.logo} logo_hover={this.state.settings.logo} height="17" nav={"/about"}/>

          <ArchiveSearch />

        </NavTop>

        <Toast/>
        {children}
        <Footer>
          <div>
            <span>{this.state.settings.organisation?'© ' + this.state.settings.organisation.name:''}</span>
            <SocialIcons links={this.state.settings.organisation?this.state.settings.organisation.links:null}/>
            <a href={this.state.settings.organisation?this.state.settings.organisation.website:''}>
              <strong>{this.state.settings.organisation?this.state.settings.organisation.name:''}</strong>
            </a>

          </div>
          <Line/>
          <ArcticArchiveFooter />
        </Footer>
      </Fragment>

    );
  }
}

export default ArcticArchiveFrame;
