import React, { Component, Fragment } from 'react';
import {KVP} from 'polar-ui';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import './index.css';

class ResourceDescription extends Component {
  constructor(props) {

    super(props);
    this.state = {
      data: this.props.data,
      granularity: this.props.granularity,
    }
  }

  displayArrayCSV(array, property) {

    if(array && property) {
      if(!Array.isArray(array)) {
        array = [array];
      }
      var returnValue = [];
      for (var i = 0; i < array.length; i++) {
        let separator = "";
        if(array[i+1]){
          separator = ", ";
        }
        try{
          if(array[i] && array[i][property]){
            let keywords = array[i][property].toString();

            let keywordsUrl;
            if(keywords && keywords.length>0){
              keywordsUrl = keywords.replace(/ /g,"+");
            }
            if(array[i].id && this.props.api){
            // to search as a keyword:
            //
            // returnValue.push(<Fragment
            //   key={array[i].id}><NavLink to={`${this.props.api}?keywords=${keywordsUrl}`}>{keywords}</NavLink>{separator}
            //   </Fragment>);
            // to search by id:
            //
            returnValue.push(
              <Fragment key={array[i].id}>
                <NavLink to={`${this.props.api}?keywords=${array[i].id}&header=${keywordsUrl}`}>{array[i][property]}</NavLink>
                {separator}
              </Fragment>);
            }else{
              returnValue.push(<Fragment >{keywords + separator}</Fragment>);
            }
          }
        }catch (e) {
          console.log(e);
        }
      }
      return returnValue;
    }
  }
  displayObject(object, properties) {
    // object is an array of objects [{name: "name1", age: "11"}, {name: "alexandria", age: "2700"}]
    // properties is expected to be an array [{label: "label0", property: "property0", style: "font-weight: bold"}, {label: "label1", property: "property1"}]
    let display = "";
    if(object && typeof object === 'object'){

      if(properties && Array.isArray(properties)){
        return properties.map(({label, property, skipApi, style}) => {
          if(label){
            if(object && object.id && this.props.api && !skipApi){
              let keywords = object[property].toString();
              let keywordsUrl;
              if(keywords && keywords.length>0){
                keywordsUrl = keywords.replace(/ /g,"+");
              }
              // to search as a keyword:
              //
              // return (<KVP
              // key={label}
              // style={style}
              // k={label}
              // v={<NavLink to={`${this.props.api}?keywords=${keywordsUrl}`}>{keywords}</NavLink>} />);
              //
              //
              //
              // to search by id:
              //
              return (<KVP
                key={label}
                k={label}
                v={<NavLink to={`${this.props.api}?keywords=${object.id}&header=${keywordsUrl}`}>{object[property]}</NavLink>} />);

            }else {
              return (<KVP key={label} k={label} v={object[property]} />);
            }

          }
            return object[property];
        })
      }else{
        //return this.displayObject(object, Object.keys(object).map((property)=>{property, label: property}));
      }
    }
  }
  displayArray(array, properties) {
    // array is an array of objects [{name: "name1", age: "11"}, {name: "alexandria", age: "2700"}]
    // properties is expected to be an array [{label: "label0", property: "property0", style: "font-weight: bold"}, {label: "label1", property: "property1"}]

    let display;
    if(array && Array.isArray(array)){
      return array.map((element) => this.displayObject(element, properties))
    }else {
      return null;
    }
  }
  isRTL(s){
    if(s){
      var rtlChars        = '\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC\u0600-\u06FF',
          rtlDirCheck     = new RegExp('^[^'+rtlChars+']*?['+rtlChars+']');
      return rtlDirCheck.test(s);
    }
    return;
  }
  getGLevel1(){
    let titleStyle = ["title"];
    let subtitleStyle = ["sub-title"];
    if(this.isRTL(this.props.data.title)){
      titleStyle.push("rtl");
      subtitleStyle.push("rtl");
    }

    return (
      <React.Fragment>
        <div className={titleStyle.join(' ')}><strong >{ this.props.data.title ? (this.props.data.title) : null }</strong> <span>{ this.props.data.year ? (" (" + this.props.data.year + ")") : null }</span></div>
        <div className={subtitleStyle.join(' ')}>
          <KVP k="by" v={this.displayArrayCSV(this.props.data.creators, "name")} className="plain"/></div>

      </React.Fragment>
    );
  }
  getGLevel2(){
    //duration, country, location
    //description, project:title, languages, types, subjects, directors, producers,
    //cinematographers, actors, publishers, resolution,
    // { this.props.data.date ? <KVP key={this.props.data.date} k="date" v={this.displayArrayCSV([this.props.data.date])} api={this.props.api}/> : null }


    // { this.props.data.type ? <KVP k="type" v={this.displayArrayCSV(this.props.data.type, "name")} /> : null }
    // { this.props.data.subject ? <KVP k="subjects" v={this.displayArrayCSV(this.props.data.subject, "name")} /> : null }
    // { this.props.data.language ? <KVP k="Languages:" v={this.displayArrayCSV(this.props.data.language, "name")} /> : null }
    // { this.props.data.director ? <KVP k="Directed by:" v={this.displayArrayCSV(this.props.data.director, "name")} /> : null }


    return (
      <React.Fragment>
        {this.getGLevel1()}

        <KVP k="Duration:" v={this.props.data.duration} />
        <KVP k=" " className="text" v={this.props.data.description} />
        { this.props.data.projects ? ( this.displayArray(this.props.data.projects, [{label:"Project:", property:"title", style: {marginTop: "17px", fontWeight:"bold"}}]) ) : null }

        { this.props.data.countries ? <KVP key={this.props.data.countries.id} k="Country:" v={this.displayArrayCSV(this.props.data.countries, "name")} api={this.props.api}/> : null }
        { this.props.data.locations ? <KVP k="Location:" v={this.displayArrayCSV(this.props.data.locations, "name")} /> : null }

        { this.props.data.producers ? <KVP k="Produced by:" v={this.displayArrayCSV(this.props.data.producers, "name")} /> : null }
        { this.props.data.cinematographers ? <KVP k="Cinematography:" v={this.displayArrayCSV(this.props.data.cinematographers, "name")} /> : null }
        { this.props.data.actors ? <KVP k="Featuring:" v={this.displayArrayCSV(this.props.data.actors, "name")} /> : null }
        { this.props.data.publishers ? <KVP k="Published by:" v={this.displayArrayCSV(this.props.data.publishers, "name")} /> : null }

      </React.Fragment>
    );
  }
  getGLevel3(){
    //project:description, subtitles, channels, colors, aspectRatio
    return (
      <React.Fragment>
        {this.getGLevel1()}

        <KVP k="Duration:" v={this.props.data.duration} />
        <KVP k=" " className="text" v={this.props.data.description} />
        { this.props.data.projects ? ( this.displayArray(this.props.data.projects, [{label:"Project:", property:"title", style: {marginTop: "17px", fontWeight:"bold"}}]) ) : null }
        { this.props.data.projects ? ( this.displayArray(this.props.data.projects, [{label:" ", property:"description", skipApi:true, style: {marginBottom: "17px"}}]) ) : null }

        { this.props.data.countries ? <KVP key={this.props.data.countries.id} k="Country:" v={this.displayArrayCSV(this.props.data.countries, "name")} api={this.props.api}/> : null }
        { this.props.data.locations ? <KVP k="Location:" v={this.displayArrayCSV(this.props.data.locations, "name")} /> : null }

        { this.props.data.producers ? <KVP k="Produced by:" v={this.displayArrayCSV(this.props.data.producers, "name")} /> : null }
        { this.props.data.cinematographers ? <KVP k="Cinematography:" v={this.displayArrayCSV(this.props.data.cinematographers, "name")} /> : null }
        { this.props.data.actors ? <KVP k="Featuring:" v={this.displayArrayCSV(this.props.data.actors, "name")} /> : null }
        { this.props.data.publishers ? <KVP k="Published by:" v={this.displayArrayCSV(this.props.data.publishers, "name")} /> : null }

      </React.Fragment>
    );
  }
  getGLevel4(){
    //posterFrame, parts, cutsPerMinute, source:identifier, source:embedUrl, source:-medium
    return (
      <React.Fragment>
        {this.getGLevel3()}
        { this.props.data.subtitles ? <KVP k="subtitles" v={this.displayArrayCSV(this.props.data.subtitles, "name")} /> : null }
        { this.props.data.channel ? <KVP k="channel" v={this.displayArrayCSV(this.props.data.channel, "name")} /> : null }
        { this.props.data.color ? <KVP k="color" v={this.displayArrayCSV(this.props.data.color, "name")} /> : null }
        <KVP k="resolution" v={this.props.data.resolution} />
        <KVP k="aspect ratio" v={this.props.data.aspectRatio} />
        <KVP k="poster frame" v={this.props.data.posterFrame} />
        <KVP k="parts" v={this.props.data.parts} />
        <KVP k="cuts per minute" v={this.props.data.cutsPerMinute} />


        { this.props.data.sources ? ( this.displayArray(this.props.data.sources, [{label:"identifier", property:"identifier"}, {label:"embed URL", property:"embedUrl"}]) ) : null }
      </React.Fragment>
    );
  }
  render() {
    let {data} = this.props;

    if(data && Object.keys(data).length !== 0) {
      return (

        <React.Fragment>
        { (this.state.granularity==="1") ? (this.getGLevel1()) : null }
        { (this.state.granularity==="2") ? (this.getGLevel2()) : null }
        { (this.state.granularity==="3") ? (this.getGLevel3()) : null }
        { (this.state.granularity==="4") ? (this.getGLevel4()) : null }
        </React.Fragment>
      );
    }
    return null;


  }

}

ResourceDescription.propTypes = {
  data: {
    title: PropTypes.string,
    creator: PropTypes.string,
  }
};

ResourceDescription.defaultProps = {
  data: {
    title: "",
    creator: "",
  },
  granularity: 1,

};


export default ResourceDescription;
