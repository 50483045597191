import {API_URL} from './config';

const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class API {
  //fix this class.. TODO: code redundancy
  static async getArchiveSettings(user = {}, data = {}) {
    return axios({
      method: 'post',
      url: API_URL+'/setup/archive',
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: {
        data,
        user,
      }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  }

}
